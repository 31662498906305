<template>
  <div class="view-user">
    <!-- 组件 name 值 -->
    <keep-alive :include="['TroubleList']">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "TroubleFix"
};
</script>

<style>
.view-user {
  height: 100%;
  overflow: hidden;
}
</style>
